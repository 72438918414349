@font-face {
  font-family: 'Rubik';
  font-weight: normal;
  src: url('/assets/webfonts/rubik-v21-latin-regular.woff2') format('woff2'),
       url('/assets/webfonts/rubik-v21-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src: url('/assets/webfonts/rubik-v21-latin-500.woff2') format('woff2'),
       url('/assets/webfonts/rubik-v21-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  src: url('/assets/webfonts/rubik-v21-latin-700.woff2') format('woff2'),
       url('/assets/webfonts/rubik-v21-latin-700.woff') format('woff');
}

::selection {
  background-color: #ff1e87;
  color: white;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity 500ms;
}

.lazyloaded {
  opacity: 1;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100vw - 2.5rem));
    }
  }

  [x-cloak] { display: none !important; }
}

.show-menu-clip-path {
  clip-path: circle(810px at 100% 0);
}

.hide-menu-clip-path {
  clip-path: circle(0 at 100% 0);
}

@layer utilities {
  .marquee-fragment::before {
    position: relative;
    top: 0.66rem;
    content: '';
    display: inline-block;
    width: 1rem;
    height: 3rem;
    margin-right: 5rem;
    margin-left: 5rem;
    background: url('/assets/images/arrow_down.svg') no-repeat;
  }

  .arrow-right-b::before {
    display: inline-block;
    margin-left: -3rem;
    width: 3rem;
    height: 1rem;
    position: relative;
    top: 7px;
    left: -20px;
    content: '';
    background: url('/assets/images/arrow_right_pink.svg') no-repeat;
  }

  .arrow-right-a-sm::after {
    display: inline-block;
    margin-left: 0.125rem;
    width: 1.5rem;
    height: 1rem;
    position: relative;
    top: 7px;
    content: '';
    background-image: url('/assets/images/arrow_right_pink--small.svg');
    background-repeat: no-repeat;
    transition: all .2s ease;
  }

  .arrow-right-a-sm:not([disabled]):hover::after {
    transform: translateX(3px);
  }
}
